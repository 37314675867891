<template>
  <router-view />
</template>

<style lang="scss">
  html {
    height: 100%;
    width: 100%;
  }
  body {
    margin: 0;
    height: 100%;
    width: 100%;
  }
  #app {
    text-align: center;
    color: #2c3e50;

    height: 100%;
    width: 100%;
  }
</style>
