<template>
  <div class="home">
    <div class="logo-view" />
    <div class="welcome-text">网站建设中</div>
    <div class="beian-view">
      <a href="https://beian.miit.gov.cn/" target="_blank" class="beian-text"
        >渝ICP备2023010310号</a
      >
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'HomeView',
    components: {},

    methods: {
      clickGXBA() {
        window.open();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .home {
    position: relative;
    width: 100%;
    height: 100%;

    background-color: #ffe104;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo-view {
      width: 512px;
      height: 512px;

      background-image: url(~@/assets/logo.png);
    }

    .welcome-text {
      font-size: 30px;
      font-weight: 900;
    }

    .beian-view {
      position: absolute;

      bottom: 10px;
    }
  }
</style>
